import React, { memo } from 'react';
import cx from 'classnames';
import {
  CurrencyDollarIcon,
  PlayIcon,
  InformationCircleIcon
} from '@heroicons/react/24/outline';
import Tooltip from '@components/tooltip';
import { getPartnerColorClasses, getPartnerTooltip } from '@utils/findPartner';
import { THRESHOLD } from '@constants/index';

const toolTipContent = (partnerType, isBoldCertified) => (
  <div className='pr-3'>
    <div
      className={cx({
        'pb-3': isBoldCertified
      })}
    >
      <h1 className={cx('py-2', getPartnerColorClasses(partnerType))}>
        {partnerType} Dealer
      </h1>
      <p className='font-proLight normal-case tracking-normal'>
        {getPartnerTooltip(partnerType)}
      </p>
    </div>
    {isBoldCertified && (
      <>
        <hr />
        <div className='py-2'>Bold Certified</div>
        <p className='font-proLight normal-case tracking-normal'>
          Kohler Bold Certified dealers provide best-in-class customer support.
          These loyal Kohler dealers complete advanced certifications, meet
          strict service requirements and provide the gracious experience that
          you expect from Kohler.
        </p>
      </>
    )}
  </div>
);

/**
 * PartnerResult Component
 */
const PartnerResultItem = ({
  id,
  name,
  partnerType,
  isBoldCertified,
  distance,
  address,
  phone,
  financingOptions,
  virtualVisits,
  position,
  length,
  requestQuoteLayover,
  sapCustomerNumber,
  website,
  acceptsQuotations,
  generatorCertification
}) => {
  const format = phoneNumberString => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3];
    }
    console.log(`Failed to format phone number: ${phoneNumberString}`);
    return phoneNumberString;
  };

  const formatPhoneNumber = phone => {
    const phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
    return phoneRe.test(phone) ? format(phone) : phone;
  };

  return (
    <section className='flex flex-col bg-neutrals-gray200 -z-10 p-5'>
      <div className='flex justify-between'>
        <h1 className='text-lg font-ProMedium max-w-[90%]'>
          {name?.toUpperCase()}
        </h1>
        <div className='flex font-semibold float-right text-sm'>{distance}</div>
      </div>
      <div className='flex mt-2.5'>
        <label className='flex uppercase font-ProBold text-xs'>
          {partnerType && (
            <span className={getPartnerColorClasses(partnerType)}>
              {partnerType} dealer
            </span>
          )}
          {isBoldCertified && (
            <span
              className={cx({
                'ml-1': partnerType
              })}
            >
              {partnerType && <>| </>}Bold Certified
            </span>
          )}
          {(partnerType || isBoldCertified) && (
            <Tooltip
              contentPrefix={''}
              content={toolTipContent(partnerType, isBoldCertified)}
              className={cx('md:w-[340px] left-3', {
                '!top-[-165px]':
                  position === length - THRESHOLD && position !== THRESHOLD - 1
              })}
            >
              <InformationCircleIcon className='font-light h-6 ml-1 -mt-[1px]' />
            </Tooltip>
          )}
        </label>
      </div>
      <div className='flex flex-col xl:flex-row justify-between mt-[15px] text-xs'>
        <div className='flex flex-row justify-between'>
          <div className='flex flex-col gap-2'>
            <label>{address}</label>
            {phone && (
              <a
                href={`tel:${phone}`}
                className='underline underline-offset-2 font-bold max-w-[100px]'
              >
                {formatPhoneNumber(phone)}
              </a>
            )}
            <div className='text-gray-600'>{generatorCertification}</div>
          </div>
        </div>
        <div className='flex flex-row xl:flex-col my-[20px] xl:my-2 gap-2'>
          {financingOptions?.length > 0 && (
            <label>
              <CurrencyDollarIcon className='h-4 inline mr-1.5 text-gray-500' />
              Financing Options
            </label>
          )}
          {virtualVisits && (
            <label>
              <PlayIcon className='h-4 inline mr-1.5 text-gray-500' />
              Virtual Site Visit
            </label>
          )}
        </div>
      </div>
      {(acceptsQuotations || website) && (
        <div className='mt-6 flex-wrap uppercase whitespace-nowrap tracking-wide justify-start text-xs grid grid-flow-col text-primary underline underline-offset-1 font-BallingerMonoMd'>
          {acceptsQuotations && (
            <a
              className='flex mr-[30px]'
              onClick={() => requestQuoteLayover(id, name, sapCustomerNumber)}
              onKeyDown={event => {
                if (event.key === 'Enter' || event.key === 'Space') {
                  requestQuoteLayover(id, name, sapCustomerNumber);
                }
              }}
            >
              Request a Free quote
            </a>
          )}
          {website && (
            <a
              href={website}
              rel='noopener noreferrer'
              target='_blank'
              className='flex'
            >
              website
            </a>
          )}
        </div>
      )}
    </section>
  );
};

export default memo(PartnerResultItem);
