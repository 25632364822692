'use-client';

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { renderToString } from 'react-dom/server';
import { MapPinIcon } from '@heroicons/react/24/solid';
import {
  getInfoboxTemplate,
  partnerType
} from '@components/findDealer/common.methods';
import Loader from '@components/loader';
import { getPartnerIconColor, getPartnerIconPng } from '@utils/findPartner';

export const BingMapsContext = createContext(null);

export function BingMapsProvider({ children }) {
  const [isLoaded, setIsLoaded] = useState(false);

  const checker = useCallback(() => {
    setTimeout(() => {
      if (window.bingMapsLoaded) {
        setIsLoaded(true);
        console.log('Bing map script loaded---');
      } else {
        console.log('Timeout Checker ---- Bing map is not loaded yet');
        checker();
      }
    }, 1000);
  }, []);

  useEffect(checker, [checker]);

  return (
    <BingMapsContext.Provider value={isLoaded}>
      {children}
    </BingMapsContext.Provider>
  );
}

export function BingMap({
  width = '100%',
  height = '100%',
  pushPins,
  center,
  userLocation = [],
  legend = null,
  highlightPin
}) {
  const isLoaded = useContext(BingMapsContext);
  const mapRef = useRef(null);
  const map = useRef(null);
  const [userLocationDenied, setUserLocationDenied] = useState(false);
  const [usrLoc, setUrsLoc] = useState([]);
  const [browser, setBrowser] = useState('');
  let infobox;
  function pushPinMouseOver(e) {
    if (e.target.metadata) {
      const partnerType = e?.target?.metadata?.partnerType;
      const iconColor = getPartnerIconColor(partnerType);
      infobox.setOptions({
        visible: true,
        location: e.target.getLocation(),
        showPointer: true,
        htmlContent: getInfoboxTemplate(e.target?.metadata?.content)
      });
    }
  }

  function pushPinMouseOut(e) {
    if (e.target.metadata) {
      infobox.setOptions({
        visible: false
      });
    }
    const partnerType = e?.target?.metadata?.partnerType;
    const iconColor = getPartnerIconColor(partnerType);
  }

  function pushPinClick(e) {
    let iconColor;
    if (e.target.metadata) {
      iconColor = getPartnerIconColor(e?.target?.metadata?.partnerType);
      console.log('hello 213 123 123 123');
      infobox.setOptions({
        visible: true,
        location: e.target.getLocation(),
        showPointer: true,
        htmlContent: getInfoboxTemplate(e.target?.metadata?.content)
      });
    }
    setTimeout(() => {
      if (e.target.metadata) {
        infobox.setOptions({
          visible: false
        });
      }
    }, 3000);
  }

  useEffect(() => {
    if (window) {
      setBrowser(
        window?.navigator?.userAgent?.toLowerCase()?.includes('firefox')
          ? 'firefox'
          : 'other'
      );
    }
  }, []);

  const updateMapPins = (pushPinsToAdd, map, newHighlightPin) => {
    // remove all existing PINS
    map.entities.clear();
    // skip setview in this case
    const { Maps } = window.Microsoft;
    infobox = new Maps.Infobox(map.getCenter(), {
      visible: false
    });

    infobox.setMap(map);
    setUserLocation(userLocation, true);
    // check for highliting PIN
    const hp = newHighlightPin || highlightPin;
    pushPinsToAdd.forEach(pushPin => {
      const location = new Microsoft.Maps.Location(
        pushPin.location[0],
        pushPin.location[1]
      );
      const newPin = new Microsoft.Maps.Pushpin(location, {
        icon:
          browser === 'firefox'
            ? getPartnerIconPng(pushPin.partnerType)
            : renderToString(
                <MapPinIcon
                  opacity={hp && pushPin.id !== hp ? 0.4 : 1}
                  height={30}
                  color={getPartnerIconColor(pushPin.partnerType)}
                />
              ),
        iconSize:
          hp && pushPin.id === hp
            ? new Microsoft.Maps.Size(40, 40)
            : new Microsoft.Maps.Size(30, 30),
        anchor: new Microsoft.Maps.Point(10, 40)
      });
      newPin.metadata = {
        content: pushPin,
        LocationType: pushPin?.partnerType
      };

      if (pushPin?.active) {
        infobox.setOptions({
          visible: true,
          location: newPin.getLocation(),
          htmlContent: getInfoboxTemplate(pushPin)
        });
      }

      Maps.Events.addHandler(newPin, 'mouseover', pushPinMouseOver);
      Maps.Events.addHandler(newPin, 'mouseout', pushPinMouseOut);
      Maps.Events.addHandler(newPin, 'click', pushPinClick);

      map.entities.push(newPin);
    });
  };

  // Highlight perticular pin on MAP
  useEffect(() => {
    if (map.current) {
      updateMapPins(pushPins, map.current, highlightPin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map.current, highlightPin]);

  useEffect(() => {
    if (map.current && center?.length === 2) {
      //Center the map on the user's location.
      console.log('Center setview', center);
      map.current.setView({
        center: new Microsoft.Maps.Location(center[0], center[1]),
        zoom: 10
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map.current, center]);

  useEffect(() => {
    if (map.current) {
      updateMapPins(pushPins, map.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map.current, center, pushPins]);

  const setUserLocation = (newLoc = [], skipSetView = false) => {
    if (!userLocationDenied && !newLoc.length) {
      //Request the user's location
      navigator.geolocation.getCurrentPosition(
        function success(position) {
          let loc = new Microsoft.Maps.Location(
            position.coords.latitude,
            position.coords.longitude
          );
          //Add a pushpin at the user's location.
          let pin = new Microsoft.Maps.Pushpin(loc, {
            color: '#4171DF'
          });
          map.current.entities.push(pin);
          //Center the map on the user's location.
          console.log('User location setview --- auto position -> ', position);
          if (!skipSetView) {
            map.current.setView({ center: loc, zoom: 10 });
          }
        },
        function error(e) {
          if (e.code === 1) {
            // user location denied;
            setUserLocationDenied(true);
          }
        },
        { enableHighAccuracy: true }
      );
    } else if (newLoc?.length === 2) {
      let loc = new Microsoft.Maps.Location(newLoc[0], newLoc[1]);
      //Add a pushpin at the user's location.
      let pin = new Microsoft.Maps.Pushpin(loc, {
        color: '#4171DF'
      });
      map.current.entities.push(pin);
      //Center the map on the user's location.
      console.log('User location 2 setview ', newLoc);
      if (!skipSetView) {
        map.current.setView({ center: loc, zoom: 10 });
      }
    }
  };

  useEffect(() => {
    if (
      map.current &&
      userLocation?.length === 2 &&
      (usrLoc.length !== userLocation.length ||
        usrLoc[0] !== userLocation[0] ||
        usrLoc[1] !== userLocation[1])
    ) {
      setUserLocation(userLocation);
      setUrsLoc(userLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map.current, userLocation, usrLoc]);

  useEffect(() => {
    try {
      if (isLoaded && mapRef.current) {
        // create a map only once
        map.current = new Microsoft.Maps.Map(mapRef.current, {
          mapTypeId: Microsoft.Maps.MapTypeId.grayscale,
          zoom: 10,
          customMapStyle: {
            settings: {
              landColor: '#F0F0F5'
            }
          }
        });
      }
    } catch (error) {
      console.error('Error loading Bing Map.', error);
    }
  }, [isLoaded]);

  if (!isLoaded) {
    return (
      <>
        <div data-testid='bingmap'></div>
        <Loader variant='page-spinner' className='h-24 w-24 ' />
      </>
    );
  }

  return (
    <>
      {isLoaded && center ? legend : null}
      <div ref={mapRef} style={{ width, height }} data-testid='bingmap'></div>
    </>
  );
}
